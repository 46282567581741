import Application from '@/store/Models/Application'
export default {

    // From MyLeads
    getApplications(agent_code, filters, options) {
        Application.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("getApplicationsQ");
        return this.getRequest('/api/private/applications/' + agent_code, params, "getApplicationsQ").then(function(json) {
            if (typeof json.meta == 'undefined') {
                return json;
            }
            Application.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
            return json;
        })
    },
    getSubmittedApplications(agent_code, filters, options) {
        Application.commit((state) => {
            state.loading = true
        })
        const params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("getSubmittedApplications");
        return this.getRequest('/api/private/new_business/application_audit/' + agent_code, params, "getSubmittedApplications")
			.then((json) => {
				Application.commit((state) => {
					state.loading = false
					state.total = json.meta?.total || json.data.length
					if (json.filters) {
						state.availableFilters = json.filters
						state.filter_keys = Object.keys(json.filters)
					}
				})
				return json
			})
    },
    updateApplication(agent_code, application) {
		// TODO wait backend task
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(application.isNotDuplicate ? { success: true } : {
					success: false,
					duplicate: { ...application },
					errors: {
						ApplicationPDFURL: [
							"The PDF URL field is required."
						]
					}
				});
			}, 2000);
		});
        // return this.postRequest('/api/private/new_business/application_submission/' + agent_code, application)
    },
    checkCarrier(agent_code, carrierId) {
        return this.getRequest('/api/private/new_business/writing_numbers_active/' + agent_code, {
			CarrierID: carrierId,
		})
    },
}
